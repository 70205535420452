import gsap, { getUnit } from "gsap";
import axios from "axios";
import Pikaday from "pikaday";
import { closeAccordion, toggleAccordion, toast } from "./utis";
import JustValidate from "just-validate";
import ButtonBracket from "./ButtonBracket";
import buttonSubmit from "./ButtonSubmit";

export default class PG_Contact {
    constructor() {
        this.container = document.querySelector(".page-contact");

        this.type = null;

        this.getParams();
    }

    getParams() {
        const url = new URL(window.location.href);
        const urlParams = url.searchParams;
        const type = urlParams.get("model");

        if (type) {
            this.initRequest("ai-model");
        } else {
            this.intro();
        }
    }

    intro() {
        const contacts = gsap.utils.toArray(".contact-wrap");
        gsap.set(contacts[0], { display: "grid" });
        gsap.to(contacts[0], { opacity: 1, duration: 0.5, ease: "power1.inOut" });

        gsap.utils.toArray(".button-bracket", contacts[0]).forEach((button) => {
            new ButtonBracket({ button: button });
            button.addEventListener("click", () => {
                this.initRequest(button.getAttribute("data-type"));
            });
        });
    }

    initRequest(type) {
        const contacts = gsap.utils.toArray(".contact-wrap")[0];

        gsap.to(contacts, {
            autoAlpha: 0,
            duration: 0.4,
            ease: "power1.inOut",
            onComplete: () => {
                gsap.set(contacts, { display: "none" });
                gsap.set(this.container.querySelector(`.view-${type}`), { display: "grid" });
                gsap.to(this.container.querySelector(`.view-${type}`), { opacity: 1, duration: 0.6, ease: "power1.inOut" });
            },
        });

        if (type === "ai-model") {
            new Request_Model();
        }

        if (type === "etc") {
            new Request_Etc();
        }
    }
}

class Request_Etc {
    constructor() {
        this.form = document.querySelector(".view-etc");
        this.buttonSubmit = this.form.querySelector(".button-submit");
        this.files = [];
        this.initEvent();
        buttonSubmit(this.form.querySelector(".button-submit"));
    }

    initEvent() {
        this.initFiles();
        this.initValidation();
    }
    eventFile(file) {
        if (!file) return;

        const MAX_SIZE = 1024 * 1024 * 20;

        if (file.size > MAX_SIZE) {
            toast("용량 초과로 업로드가 불가능합니다.");
            return;
        }
        if (this.files.length > 2) {
            toast("3개까지 첨부 가능합니다.");
            return;
        }

        const fileWrap = this.form.querySelector(".attachments-wrap");
        this.files.push(file);
        const button = document.createElement("button");
        button.type = "button";
        button.setAttribute("name", file.name);

        const name = file.name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        button.innerHTML = `<span class="file-name">${fileName}</span><span>.${ext}</span>`;

        fileWrap.appendChild(button);

        button.addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault();
            const newFiles = this.files.filter((f) => f.name !== file.name);
            this.files = newFiles;
            button.remove();
        });
    }

    initFiles() {
        const that = this;
        this.form.querySelector("#attachments").onchange = (e) => {
            if (e.target.files) {
                that.eventFile(e.target.files[0]);
            }
        };
        this.preventWheel();
    }

    resetForm() {
        this.buttonSubmit.disabled = false;
        gsap.utils.toArray("form input", this.form).forEach((input) => {
            if (input.type == "checkbox") {
                input.checked = false;
            } else {
                input.value = "";
            }
        });
        this.files = [];
        this.form.querySelector(".attachments-wrap").innerHTML = "";
        gsap.utils.toArray("textarea").forEach((input) => (input.value = ""));
        gsap.utils.toArray(".accordion-container").forEach((a) => {
            if (a.querySelector(".input-value")) {
                a.querySelector(".input-value").innerHTML = "";
                a.querySelector(".input-value").classList.add("empty");
            }
        });
    }
    preventWheel() {
        const inputTypeNumbers = document.querySelectorAll("input[type=number]");

        for (var a = 0; a < inputTypeNumbers.length; a++) {
            inputTypeNumbers[a].onwheel = function (event) {
                event.target.blur();
            };
        }
    }

    async onSubmit(type) {
        try {
            const formDATA = new FormData();

            this.buttonSubmit.disabled = true;
            gsap.utils.toArray("form input", this.form).forEach((input) => {
                if (input.type !== "file") {
                    if (input.type == "checkbox") {
                        const v = input.value == "on" ? true : false;
                        formDATA.append(input.id, v);
                    } else {
                        formDATA.append(input.id, input.value);
                    }
                }
            });

            if (this.form.querySelector("#description").value) {
                formDATA.append("description", this.form.querySelector("#description").value);
            }

            if (this.files.length > 0) {
                this.files.map((f) => {
                    formDATA.append("attachments[]", f);
                });
            }

            const response = await axios.post(`https://api.blurblur.ai/api/contact/etc`, formDATA, {});

            if (response.status === 201) {
                toast("문의가 접수되었습니다.");
                this.resetForm();
            }
        } catch (error) {
            this.buttonSubmit.disabled = false;
        } finally {
            this.buttonSubmit.disabled = false;
        }
    }

    initValidation() {
        const validator = new JustValidate("#etc", {
            validateBeforeSubmitting: true,
            focusInvalidField: true,
        });
        validator.onSuccess((event) => {

            this.onSubmit("etc");
        });
        validator.addField(this.form.querySelector("#title"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#description"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-email"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
            {
                rule: "email",
                errorMessage: "올바르지 않은 형식입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-name"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-company"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-allow_personal_rule"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-phone_number"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
            {
                rule: "customRegexp",
                value: /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                errorMessage: "올바르지 않은 형식입니다.",
            },
        ]);

        validator.addField(this.form.querySelector("#client-company_registration_number"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
            {
                rule: "minLength",
                value: 10,
                errorMessage: "올바르지 않은 형식입니다.",
                handlerChange: (value, context) => {
                },
            },
        ]);
    }
}

class Request_Model {
    constructor() {
        this.form = document.querySelector(".view-ai-model");
        this.buttonSubmit = this.form.querySelector(".button-submit");
        this.selectModel = null;
        this.getParams();
        this.initEvent();
        this.isMobile = document.body.classList.contains("view-mobile");

        this.getCategory();
        this.getModels();
        this.initCalendar();
        this.models = [];
        buttonSubmit(this.form.querySelector(".button-submit"));
    }
    getParams() {
        const url = new URL(window.location.href);
        const urlParams = url.searchParams;
        const type = urlParams.get("model");

        this.selectModel = type;
    }

    createAccordion(_data) {
        const container = this.form.querySelector(".accordion-category");
        const content = container.querySelector(".accordion-content");

        _data.map((d) => {
            const button = document.createElement("button");
            button.type = "button";
            button.setAttribute("data-value", d.id);
            button.innerHTML = d.name;
            content.append(button);
        });

        this.clickOption(container);
    }

    createAccordion_model(_data) {
        const container = this.form.querySelector(".select-model");
        const content = container.querySelector(".accordion-content");

        const thumb = this.form.querySelector(".model-thumb");
        _data.map((d) => {
            const button = document.createElement("button");
            button.type = "button";
            button.setAttribute("data-value", d.id);
            button.innerHTML = d.name_en;
            content.append(button);

            const img = document.createElement("img");
            img.src = d.thumbnail;
            img.setAttribute("data-value", d.id);
            thumb.appendChild(img);
        });

        const value = container.querySelector(".input-value");

        if (this.selectModel == null) {
            value.innerHTML = _data[0].name_en;
            this.form.querySelector("#request_type").value = 0;
            this.form.querySelector("#ai_model").value = _data[0].id;
            this.changeModel();
        } else {
            value.innerHTML = _data.filter((d) => d.id == this.selectModel)[0].name_en;
            this.form.querySelector("#request_type").value = 0;
            this.form.querySelector("#ai_model").value = this.selectModel;
            this.changeModel();
        }
        this.clickOption(container);
        this.initValidation();
    }

    async getModels() {
        try {
            const response = await axios.get(`https://api.blurblur.ai/api/ai-model`);

            this.models.push(response.data);
            this.createAccordion_model(response.data);
        } catch (error) {
        } finally {
        }
    }

    async getCategory() {
        try {
            const response = await axios.get(`https://api.blurblur.ai/api/category`);
            this.createAccordion(response.data);
        } catch (error) {
        } finally {
        }
    }

    initEvent() {
        gsap.utils.toArray(".accordion-container").forEach((accrordion, index) => {
            accrordion.querySelector(".accordion-button").addEventListener("click", () => {
                toggleAccordion(accrordion);
            });

            this.clickOption(accrordion);
        });
    }

    changeModel() {
        const value = this.form.querySelector("#ai_model").value;

        const thumbs = gsap.utils.toArray(".model-thumb img", this.form);

        thumbs.forEach((t) => {
            if (t.getAttribute("data-value") == value) {
                gsap.to(t, { opacity: 1, duration: 0.4, eaes: "power1.inOut" });
                t.classList.add("show");
            } else {
                if (t.classList.contains("show")) {
                    gsap.to(t, { opacity: 0, duration: 0.3, ease: "power1.inOut" });
                    t.classList.remove("show");
                }
            }

            if (this.form.querySelector("#request_type").value == 1) {
                this.form.querySelector(".accordion-request .accordion-content button").click();
            }
        });
    }

    blurToModel() {
        this.form.querySelector(".select-model .input-value").innerHTML = "New model";

        this.form.querySelector("#ai_model").setAttribute("data-prev", this.form.querySelector("#ai_model").value);
        this.form.querySelector("#ai_model").value = "null";
        gsap.to(".model-thumb", { "--blur": "8px", duration: 0.8, ease: "power1.inOut" });
    }

    unblurToModel() {
        const _p = this.form.querySelector("#ai_model").getAttribute("data-prev");

        if (_p) {
            const _target = this.models[0].filter((m) => _p == m.id);

            if (_target[0] && _target[0].name_en) {
                this.form.querySelector(".select-model .input-value").innerHTML = _target[0].name_en;
                this.form.querySelector("#ai_model").value = _p;
            }
        }
        gsap.to(".model-thumb", { "--blur": 0, duration: 0.8, ease: "power1.inOut" });
    }

    changeType() {
        const value = this.form.querySelector("#request_type").value;

        if (value == 0) {
            this.unblurToModel();
        } else {
            this.blurToModel();
        }
    }

    clickOption(accordion) {
        const buttons = gsap.utils.toArray(".accordion-content button", accordion);
        const input = accordion.querySelector("input");
        const inputDisplay = accordion.querySelector(".input-value");
        buttons.forEach((button, option) => {
            button.addEventListener("click", () => {
                input.value = button.getAttribute("data-value");
                if (inputDisplay) {
                    inputDisplay.classList.remove("empty");
                    inputDisplay.innerHTML = button.innerHTML;
                }
                closeAccordion(accordion);

                if (accordion.classList.contains("select-model")) {
                    this.changeModel();
                }

                if (accordion.classList.contains("accordion-request")) {
                    this.changeType();
                }
            });
        });
    }

    preventWheel() {
        const inputTypeNumbers = document.querySelectorAll("input[type=number]");

        for (var a = 0; a < inputTypeNumbers.length; a++) {
            inputTypeNumbers[a].onwheel = function (event) {
                event.target.blur();
            };
        }
    }

    initCalendar() {
        const that = this;
        this.preventWheel();
        this.picker = new Pikaday({
            field: that.form.querySelector(".datepicker"),
            format: "yyyy-mm-dd",
            position: "bottom right",
            // reposition: false,
            // defaultDate: new Date(new Date().setDate(new Date().getDate() + 7)),
            minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
            onClose: function () {
                if (that.picker.el) {
                    gsap.set(that.picker.el, { autoAlpha: 0 });
                }
                if (!that.isMobile) {
                }
            },
            onOpen: function () {
                if (that.picker.el) {
                    gsap.set(that.picker.el, { autoAlpha: 1 });
                }
                if (!that.isMobile) {
                    let prev = window.scrollY;
                    function _event() {
                        if (window.scrollY > 100) {
                            that.picker.hide();
                            document.removeEventListener("scroll", _event);
                        } else {
                            that.picker.adjustPosition();
                        }
                    }
                    document.addEventListener("scroll", _event);
                }
            },
            toString(date, format) {
                // you should do formatting based on the passed format,
                // but we will just return 'D/M/YYYY' for simplicity
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                that.form.querySelector(".deadline").classList.remove("empty");
                that.form.querySelector(".deadline").innerHTML = `${year}년 ${month}월 ${day}일`;

                return `${year}-${month}-${day}`;
            },
            parse(dateString, format) {
                const parts = dateString.split("/");
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1;
                const year = parseInt(parts[2], 10);
                return new Date(year, month, day);
            },
        });

        this.files = [];
        this.initFiles();
    }

    eventFile(file) {
        if (!file) return;

        const MAX_SIZE = 1024 * 1024 * 20;

        if (file.size > MAX_SIZE) {
            toast("용량 초과로 업로드가 불가능합니다.");
            return;
        }
        if (this.files.length > 2) {
            toast("3개까지 첨부 가능합니다.");
            return;
        }

        const fileWrap = this.form.querySelector(".attachments-wrap");
        this.files.push(file);
        const button = document.createElement("button");
        button.type = "button";
        button.setAttribute("name", file.name);

        const name = file.name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        button.innerHTML = `<span class="file-name">${fileName}</span><span>.${ext}</span>`;

        fileWrap.appendChild(button);

        button.addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault();
            const newFiles = this.files.filter((f) => f.name !== file.name);
            this.files = newFiles;
            button.remove();
        });
    }

    initFiles() {
        const that = this;
        this.form.querySelector("#attachments").onchange = (e) => {
            if (e.target.files) {
                that.eventFile(e.target.files[0]);
            }
        };
    }

    resetForm() {
        gsap.utils.toArray("form input", this.form).forEach((input) => {
            if (input.type == "checkbox") {
                input.checked = false;
            } else {
                input.value = "";
            }
        });
        this.files = [];
        this.form.querySelector(".attachments-wrap").innerHTML = "";
        gsap.utils.toArray("textarea").forEach((input) => (input.value = ""));
        gsap.utils.toArray(".accordion-container").forEach((a) => {
            if (a.querySelector(".input-value")) {
                a.querySelector(".input-value").innerHTML = "";
                a.querySelector(".input-value").classList.add("empty");
            }

            if (a.classList.contains("accordion-category")) {
                a.querySelector(".input-value").innerHTML = "카테고리를 선택해 주세요";
            }

            if (a.classList.contains("accordion-request")) {
            }

            this.changeModel();
        });

        this.form.querySelector(".input-date .input-value").innerHTML = "날짜를 선택해 주세요";

        this.form.querySelector(".select-model .accordion-content button").click();
        this.form.querySelector(".accordion-request .accordion-content button").click();
    }

    async onSubmit(type) {
        try {
            const formDATA = new FormData();
            this.buttonSubmit.disabled = true;

            formDATA.append("ai_model", this.form.querySelector("#ai_model").value);
            gsap.utils.toArray("form input", this.form).forEach((input) => {
                if (input.type !== "file") {
                    if (input.type == "checkbox") {
                        const v = input.value == "on" ? true : false;
                        formDATA.append(input.id, v);
                    } else {
                        formDATA.append(input.id, input.value);
                    }
                }
            });
            if (document.querySelector("#request_type")) {
                if (document.querySelector("#request_type").value == 1 || document.querySelector("#request_type").value == "1") {
                    formDATA.delete("ai_model");
                }
            }
            if (this.files.length > 0) {
                this.files.map((f) => {
                    formDATA.append("attachments[]", f);
                });
            }
            if (this.form.querySelector("#description").value) {
                formDATA.append("description", this.form.querySelector("#description").value);
            }

            const response = await axios.post(`https://api.blurblur.ai/api/contact/${type}`, formDATA, {
                headers: {
                    "Content-Type": formDATA.attachments ? "multipart/form-data" : "application/json",
                },
            });

            if (response.status === 201) {
                toast("문의가 접수되었습니다.");
                this.resetForm();
            }

        } catch (error) {
            this.buttonSubmit.disabled = false;
        } finally {
            this.buttonSubmit.disabled = false;
        }
    }

    initValidation() {
        const validator = new JustValidate("#ai", {
            validateBeforeSubmitting: true,
            focusInvalidField: true,
        });
        validator.onSuccess((event) => {

            this.onSubmit("ai-model");
            // this.form.getElementById("app-form").submit();
        });

        validator.addField(this.form.querySelector("#category"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#image_count"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#deadline"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-email"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
            {
                rule: "email",
                errorMessage: "올바르지 않은 형식입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-name"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-company"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-allow_personal_rule"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
        ]);
        validator.addField(this.form.querySelector("#client-phone_number"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
            {
                rule: "customRegexp",
                value: /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                errorMessage: "올바르지 않은 형식입니다.",
            },
        ]);

        validator.addField(this.form.querySelector("#client-company_registration_number"), [
            {
                rule: "required",
                errorMessage: "필수 입력 항목입니다.",
            },
            {
                rule: "minLength",
                value: 10,
                errorMessage: "올바르지 않은 형식입니다.",
                handlerChange: (value, context) => {
                },
            },
        ]);
    }
}
